import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import { Link } from "gatsby"
import Icon from '../components/icons';

import empowerment from '../_images/Titans-of-Empowerment.svg';

import frida from '../_images/landing-pages/frida-mendez-arce/frida-hero-small.jpg';
import eric from '../_images/landing-pages/eric-resendiz/eric-resendiz-hero-small.jpg';
import bryanRuef from '../_images/landing-pages/bryan-ruef/bryan-ruef-hero-small.jpg';
import asha from '../_images/landing-pages/asha-bhattacharya/asha-hero-small.jpg';
import james from '../_images/landing-pages/james-anthony-burandt/james-anthony-burandt-hero-small.jpg';
import makar from '../_images/landing-pages/makar-makarian/makar-makarian-empowerment-hero-small.jpg';
import alexis from '../_images/landing-pages/alexis-ruiz/alexis-ruiz-hero-small.jpg';



Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }
  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  render() {
    var pageTitle = 'Titans of Empowerment';

    return (
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="theme-landing-page" />

          {/* <meta name="description" content={pageDesc} /> */}

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          {/* <meta itemprop="description" content={pageDesc} /> */}
          {/* <meta itemprop="image" content={socialBanner} /> */}

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          {/* <meta name="twitter:description" content={pageDesc} /> */}
          {/* <meta name="twitter:image:src" content={socialBanner} /> */}

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          {/* <meta property="og:image" content={socialBanner} /> */}
          <meta property="og:image:alt" content={pageTitle} />
          {/* <meta property="og:description" content={pageDesc} /> */}
        </Helmet>


        <section className="hero">
          <div className="wrap small">
            <h1><img src={empowerment} alt={pageTitle} /></h1>
            <p>
            Titans access the tools, opportunities and support to discover their passions and convictions; develop into leaders; thrive and impact the world around them; and own their pursuit of great achievement.
            </p>
          </div>
        </section>

        <section className="profile-links">
          <ul>
            <li><Link to="/empowerment/alexis" className="profile-link" style={{ backgroundImage: `url(${alexis})` }}><span className="profile-link-text">Alexis Ruiz</span></Link></li>
            <li><Link to="/empowerment/makar" className="profile-link" style={{ backgroundImage: `url(${makar})` }}><span className="profile-link-text">Makar Makarian</span></Link></li>
            <li><Link to="/empowerment/james" className="profile-link" style={{ backgroundImage: `url(${james})` }}><span className="profile-link-text">James-Anthony Burandt</span></Link></li>
            <li><Link to="/empowerment/asha" className="profile-link" style={{ backgroundImage: `url(${asha})` }}><span className="profile-link-text">Asha Bhattacharya</span></Link></li>
            <li><Link to="/empowerment/bryan" className="profile-link" style={{ backgroundImage: `url(${bryanRuef})` }}><span className="profile-link-text">Bryan Ruef</span></Link></li>
            <li><Link to="/empowerment/eric-resendiz" className="profile-link" style={{ backgroundImage: `url(${eric})` }}><span className="profile-link-text">Eric Resendiz</span></Link></li>
            <li><Link to="/empowerment/frida-mendez-arce" className="profile-link" style={{ backgroundImage: `url(${frida})` }}><span className="profile-link-text">Frida Mendez-Arce</span></Link></li>
          </ul>
        </section>

        <section className="content">
          <div className="boiler-plate">
            <h2>About the Campaign</h2>
            <p>“It Takes a Titan: The Campaign for Cal State Fullerton,” is the university’s first-ever comprehensive philanthropic campaign. The $250 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community. It is an opportunity for the entire Titan family – faculty, staff, alumni and community members - to be the difference in elevating the potential of our remarkable students.</p>
            <Link to="/about" className="buttonBlue">Learn More</Link>
          </div>
          <div className="sidebar">
            <div className="give-cta">
              <p>Be the difference in a Titan's life.</p>
              <a href="https://give.fullerton.edu/titan-fund" className="button orange">Support Our Campaign</a>
            </div>
            <div className="other-cta">
              
              <a href="http://admissions.fullerton.edu/" className="buttonBlue outline">Learn More About Admissions</a>
              
              <button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button>

              <br />
              <h3>Stay Connected</h3>

              <ul className="social-links">
                <li><a className="facebook" href="http://www.facebook.com/csufofficial"><Icon name="facebook" alt="Facebook" /></a></li>
                <li><a className="twitter" href="http://twitter.com/csuf"><Icon name="twitter" alt="Twitter" /></a></li>
                <li><a className="instagram" href="http://instagram.com/csufofficial"><Icon name="instagram" alt="Instagram" /></a></li>
                <li><a className="linkedin" href="http://www.linkedin.com/edu/california-state-university-fullerton-17827"><Icon name="linkedin" alt="Linkedin" /></a></li>
              </ul>

            </div>
          </div>
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="resilience" college="Cal State Fullerton" />
          </div>
        </Modal>
        
      </Layout>
    );
  }
}
